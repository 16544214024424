import { makeAutoObservable, runInAction } from 'mobx';

import {
  queryConfigHistory
} from '@/api';

class CostHistory {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  configList = [];

  //获取应用列表数据
  async getConfigHistory(id) {
    let res;
    try {
      res = await queryConfigHistory({configId:id});
      // console.log(res)
      if (res) {
        runInAction(() => {
          this.configList = res;
        });
      }
    } catch (err) {}

    return res;
  }  
  
  clearConfigList() {
    this.configList = [];
  }
}

export default new CostHistory();
