import { makeAutoObservable, runInAction } from 'mobx';

import {
  queryRulePrefixs,
  queryConfig,
  updateCostConfig,
  addAudit
} from '@/api';

class CostSettings {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  rulePrefixs = [];
  config = {};

  //获取应用列表数据
  async getRulePrefixs() {
    let res;
    try {
      res = await queryRulePrefixs();
      console.log(res)
      if (res) {
        runInAction(() => {
          this.rulePrefixs = res;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取配置详情
  async getConfig(config) {
    let res;
    try {
      res = await queryConfig({prefix:config});
      console.log(res)
      if (res) {
        runInAction(() => {
          this.config = JSON.stringify(res, null, 4);
          // console.log(this.config)
        });
      }
    } catch (err) {}

    return JSON.stringify(res, null, 4);
  }



  async updateConfig(params) {
    // console.log(params)
    let res;
    try {
      res = await updateCostConfig(params);
    } catch (err) {}
    return res;
  }

  async addAudit(code) {
    console.log(code)
    let res;
    try {
      res = await addAudit(code);
    } catch (err) {}
    return res;
  }

  
  
  clearRulePrefixs() {
    this.rulePrefixs = [];
  }
}

export default new CostSettings();
