import { makeAutoObservable, runInAction } from 'mobx';

import {
  queryAccountCostsWithGroup,
  queryAccountMappingWithGroup,
  queryAppAccounts,
  queryAppCostSummary,
  queryAppCostTrendWithGroup,
  queryAppMappingWithGroup,
  queryAppServiceCosts,
  queryInfrasCostTrend,
  queryInfrastructureCosts,
  queryQuestions,
  queryReportsWithGroup,
  queryServiceCostsAnalysisWithGroup,
  queryServiceCostsRankWithGroup,
  queryServiceCostsTrendWithGroup,
  queryTieredPrices,
  queryUntaggedServiceCostsWithGroup,
  surveySubmit,
  queryUserGroups,
  queryInfrastructureCostsWithGroup,
  queryServiceCostsWithGroup,
  queryInfrasCostTrendWithGroup,
  queryAccountMappingWithCurrentUser,
  queryAppMappingWithCurrentUser,
  queryApplicationCostsWithGroup,
  queryAppAccountsWithCurrentUser
} from '@/api';


class CostDashboard {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  infrasCostData = {};
  InfrasCostTrendData = [];
  appCostsData = [];
  appMappingData = {};
  appCostTrendData = [];
  appCostTrendData2 = [];
  serviceCostsRankData = [];
  accountMappingData = {};
  serviceCostsTrendData = [];
  accountCostsData = [];
  appCostSummaryData = [];
  appServiceCostsData = {};
  serviceCostsData = [];
  untaggedServiceCostsData = [];
  appAccountsData = [];
  serviceCostsAnalysisData = [];
  tieredPrices = [];
  questions = [];
  surveyResult = {};

  // user groups
  userGroups = null;
  userDefaultGroups = null;
  userSelectedGroups = null;

  appServiceCostPage = {
    current: 1,
    pageSize: 10
  };

  serviceCostsPage = {
    current: 1,
    pageSize: 10
  };

  untaggedServiceCostsPage = {
    current: 1,
    pageSize: 10
  };

  downloadLoading = false;

  async queryInfrastructureCosts() {
    let res;
    try {
      res = await queryInfrastructureCosts();
      runInAction(() => {
        this.infrasCostData = res || {};
      });
    } catch (err) {}

    return res;
  }

  async queryInfrastructureCostsWithGroup(userSelectedGroup) {
    let res;
    try {
      res = await queryInfrastructureCostsWithGroup(userSelectedGroup);
      runInAction(() => {
        this.infrasCostData = res || {};
      });
    } catch (err) {}

    return res;
  }

  async queryInfrasCostTrend(params) {
    let res;
    try {
      res = await queryInfrasCostTrend(params);

      runInAction(() => {
        this.InfrasCostTrendData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryInfrasCostTrendWithGroup(params, body) {
    let res;
    try {
      res = await queryInfrasCostTrendWithGroup(params, body);

      runInAction(() => {
        this.InfrasCostTrendData = res || [];
      });
    } catch (err) {}

    return res;
  }

  queryAppName(appCode) {
    return this.appMappingData[appCode];
  }

  async queryApplicationCostsWithGroup(params, group) {
    let res;
    try {
      res = await queryApplicationCostsWithGroup(params, group);
      runInAction(() => {
        this.appCostsData = res?.map((item) => ({ ...item, appName: this.queryAppName(item.applicationCode) })) || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAppMappingWithGroup(group) {
    let res;
    try {
      res = await queryAppMappingWithGroup(group);

      runInAction(() => {
        this.appMappingData = res || {};
      });
    } catch (err) {}

    return this.appMappingData;
  }

  async queryAppMappingWithCurrentUser() {
    let res;
    try {
      res = await queryAppMappingWithCurrentUser();

      runInAction(() => {
        this.appMappingData = res || {};
      });
    } catch (err) {}

    return this.appMappingData;
  }

  async queryAppCostTrendWithGroup(params, group) {
    let res;
    try {
      res = await queryAppCostTrendWithGroup(params, group);
      runInAction(() => {
        this.appCostTrendData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAppCostTrend2WithGroup(params, group) {
    let res;
    try {
      res = await this.queryAppCostTrendWithGroup(params, group);
      runInAction(() => {
        this.appCostTrendData2 = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryServiceCostsRankWithGroup(params, group) {
    let res;
    try {
      res = await queryServiceCostsRankWithGroup(params, group);
      runInAction(() => {
        this.serviceCostsRankData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAccountMappingWithGroup(group) {
    let res;
    try {
      res = await queryAccountMappingWithGroup(group);
      runInAction(() => {
        this.accountMappingData = res || {};
      });
    } catch (err) {}

    return res;
  }

  async queryAccountMappingWithCurrentUser(group) {
    let res;
    try {
      res = await queryAccountMappingWithCurrentUser(group);
      runInAction(() => {
        this.accountMappingData = res || {};
      });
    } catch (err) {}

    return res;
  }

  async queryServiceCostsTrendWithGroup(params, group) {
    let res;
    try {
      res = await queryServiceCostsTrendWithGroup(params, group);
      runInAction(() => {
        this.serviceCostsTrendData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAccountCostsWithGroup(params, group) {
    let res;
    try {
      res = await queryAccountCostsWithGroup(params, group);
      runInAction(() => {
        this.accountCostsData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryServiceCostsAnalysisWithGroup(params, group) {
    let res;
    try {
      res = await queryServiceCostsAnalysisWithGroup(params, group);
      runInAction(() => {
        this.serviceCostsAnalysisData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAppCostSummary({ applicationCode, ...props }) {
    let res;
    try {
      res = await queryAppCostSummary(applicationCode, props);
      runInAction(() => {
        this.appCostSummaryData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAppServiceCosts({ applicationCode, page = 1, pageSize = 10, ...props }) {
    let res;

    const params = {
      ...props,
      page,
      size: pageSize
    };
    try {
      res = await queryAppServiceCosts(applicationCode, params);
      runInAction(() => {
        this.appServiceCostsData = res || {};
        if (res) {
          this.appServiceCostPage['current'] = page || 1;
          this.appServiceCostPage['pageSize'] = pageSize || 10;
        }
      });
    } catch (err) {}

    return res;
  }

  async queryServiceCostsWithGroup({ page = 1, pageSize = 10, ...props }, group) {
    let res;
    const params = {
      ...props,
      page,
      size: pageSize
    };
    try {
      res = await queryServiceCostsWithGroup(params, group);
      runInAction(() => {
        this.serviceCostsData = res || {};
        if (res) {
          this.serviceCostsPage['current'] = page || 1;
          this.serviceCostsPage['pageSize'] = pageSize || 10;
        }
      });
    } catch (err) {}

    return res;
  }

  async queryUntaggedServiceCostsWithGroup({ page = 1, pageSize = 10, ...props }, group) {
    let res;
    const params = {
      ...props,
      page,
      size: pageSize
    };
    try {
      res = await queryUntaggedServiceCostsWithGroup(params, group);
      runInAction(() => {
        this.untaggedServiceCostsData = res || {};
        if (res) {
          this.untaggedServiceCostsPage['current'] = page || 1;
          this.untaggedServiceCostsPage['pageSize'] = pageSize || 10;
        }
      });
    } catch (err) {}

    return res;
  }

  async queryAppAccounts(applicationCode) {
    let res;
    try {
      res = await queryAppAccounts(applicationCode);
      runInAction(() => {
        this.appAccountsData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryAppAccountsWithAccount(applicationCode) {
    let res;
    try {
      res = await queryAppAccountsWithCurrentUser(applicationCode);
      runInAction(() => {
        this.appAccountsData = res || [];
      });
    } catch (err) {}

    return res;
  }

  async queryReportsWithGroup(params, group) {
    runInAction(() => {
      this.downloadLoading = true;
    });

    try {
      const res = await queryReportsWithGroup({ ...params}, group);
      runInAction(() => {
        this.downloadLoading = false;
      });
    } catch (err) {}
  }

  //cost model

  async queryTieredPrices() {
    try {
      const res = await queryTieredPrices();
      runInAction(() => {
        this.tieredPrices = res || [];
      });
    } catch (err) {}
  }

  async queryQuestions() {
    try {
      const res = await queryQuestions();
      runInAction(() => {
        this.questions = res || [];
      });
    } catch (err) {}
  }

  async surveySubmit(params) {
    try {
      const res = await surveySubmit(params);
      runInAction(() => {
        this.surveyResult = res || {};
      });
    } catch (err) {}
  }

  async queryUserGroups() {
    try {
      const res = await queryUserGroups();
      runInAction(() => {
        this.userGroups = res.data || [];
        this.userDefaultGroups = this.userGroups.map(group => [group.value]);
        this.userSelectedGroups = this.userDefaultGroups;
      })
    } catch (err) {}
  }

  async setUserSelectedGroups(selectedGroups) {
    runInAction(() => {
      this.userSelectedGroups = selectedGroups
    })
  }
}

export default new CostDashboard();
