import { createContext } from 'react';
const XLSX = require('xlsx');

export function setItem(key, value) {
  localStorage.setItem(key, value);
}

export function getItem(key) {
  return localStorage.getItem(key);
}

export function removeItem(key) {
  localStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}

export function memoryUnitConvert(text) {
  if (!text) return '';
  const value = _.round(_.divide(text, 1024 * 1024));
  return value >= 1024 ? _.round(_.divide(value, 1024), 2) + ' GiB' : value + ' MiB';
}

export function timeDiffInSeconds(beginTime: Date, endTime: Date) {
  return (endTime.getTime() - beginTime.getTime()) / 1000;
}

// 时间(可传入时间戳或时间对象)， 格式，时区
export function parseTime(time, cFormat = '{y}-{m}-{d} {h}:{i}:{s}', zone = new Date().getTimezoneOffset() / -60) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  // 时区调整
  const utc = time + new Date(time).getTimezoneOffset() * 60000;
  const wishTime = utc + 3600000 * zone;
  date = new Date(wishTime);
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return timeStr;
}

export const C = createContext(null);

export const scrollToBottom = (eleRef) => {
  if (eleRef && eleRef.current) {
    eleRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
  }
};

export const findDuplicateNames = (arr) => {
  const nameSet = new Set();
  const duplicateNames = [];

  for (let i = 0; i < arr.length; i++) {
    const name = arr[i].name;

    if (nameSet.has(name)) {
      duplicateNames.push(name);
    } else {
      nameSet.add(name);
    }
  }

  return duplicateNames;
};

export const filterAuthorizedRoutes = (routes, authoritys) => {
  const filterRoutes = (routes) => {
    return routes.reduce((filtered, route) => {
      if (route.authority) {
        const authorityCheck = authoritys[route.authority];
        if (authorityCheck) {
          const { children, ...rest } = route;
          if (children) {
            const filteredChildren = filterRoutes(children);
            if (filteredChildren.length > 0) {
              filtered.push({ ...rest, children: filteredChildren });
            }
          } else {
            filtered.push({ ...rest });
          }
        }
      } else {
        const { children, ...rest } = route;
        if (children) {
          const filteredChildren = filterRoutes(children);
          if (filteredChildren.length > 0) {
            filtered.push({ ...rest, children: filteredChildren });
          }
        } else {
          filtered.push({ ...rest });
        }
      }
      return filtered;
    }, []);
  };

  return filterRoutes(routes);
};